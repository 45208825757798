import React, { useEffect } from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import AOS from 'aos';
import 'aos/dist/aos.css';
function Footer() {
  useEffect(()=>{
    AOS.init()
  },[])
  return (
  <div className='footer'>
      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'  data-aos="fade-up">
          <span>تابعنا علي السوشيال ميديا </span>
        </div>

        <div  data-aos="fade-up">
          
          <a href='https://twitter.com/ahsa_Tourism' target='_blank' rel='noreferrer' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='twitter' />
          </a>
          
          <a href='https://www.instagram.com/ahsa_tourism/' target='_blank' rel='noreferrer' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='instagram' />
          </a>
        
          <a href='https://www.facebook.com/profile.php?id=100067372682920' target='_blank' rel='noreferrer' className='me-4 text-reset'>
            <MDBIcon color='secondary' fab icon='facebook-f' />
          </a>
         
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='4' lg='6' xl='6' className='mx-auto mb-4' 
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500">
              <h6 className='text-uppercase fw-bold mb-4'>
                {/* <MDBIcon color='secondary' icon='gem' className='me-3' /> */}
                شركة الأحساء للسياحة والترفيه
              </h6>
              <p className='text-justify'>
              تأسست شركة الأحساء للسياحة والترفيه عام 1999, المملكة العربية السعودية
              وهى مختصة في التطوير السياحي, وتعمل في مجال إنشاء وتشغيل وصيانة المنشآت والمرافق السياحية والعامة, وتنظيم الرحلات والبرامج 
              السياحية والفعاليات والمهرجانات والمعارض والمؤتمرات والمراكز الترفيهية والمطاعم والفنادق والإستراحات والحدائق النباتية
              والحيوانية وصالات الألعاب.
              </p>
            </MDBCol>

            

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4' data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500">
              <h6 className='text-uppercase fw-bold mb-4'>إتصل بنا </h6>
              <p>
                <MDBIcon color='secondary' icon='home' className='me-2' />
              الهفوف - المنطقة الشرقية
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelopes-bulk' className='me-2' />
              الرمز البريدي - 31982
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                <a href="mailto:info@ahsana.com">info@ahsana.com</a>
              </p>
              <p>
                <MDBIcon color='secondary' icon='phone' className='me-3' /> 
                <a href="tel:+966135982888">0966135982888</a>
              </p>

            
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
        © 2023 Copyright &nbsp; 
        <a className='text-reset fw-bold' href='/'>
          AL-AHSA TOURISM Company
        </a> <br />
        <a href="https://mohammedebrahiim.online/" target='_blank' rel='noreferrer'>
         © Developed By Mohammed Hania
        </a>
      </div>
    </MDBFooter>
  </div>
  );
}

export default Footer