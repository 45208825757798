import React from 'react'
import Services from './Services'

function LayoutServices() {
  return (
    <div>
      <div className='mt-5'>&nbsb;</div>
      <Services />
    </div>
  )
}

export default LayoutServices