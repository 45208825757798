import React, { useEffect } from 'react'
// import svg from '../assets/imgs/About/What-We-Do.svg'
// import svg2 from '../assets/imgs/About/Our-Leaders.svg'
// import svg3 from '../assets/imgs/About/Mission-Vision.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";


import './Css/about_style.css'
function AboutUs() {
  const [t, i18n] = useTranslation();

  useEffect(()=>{
    AOS.init();
  },[])

  return (
    <div className='about-us' id='about'>
      <div className="container my-5">
        <div className="row">
          <div className="col-12 mb-5" data-aos="fade-up" data-aos-duration="1500">
            <h4 className='my-2 mb-3 logo-blue-text-color'>
              {t('aboutUstext1')}
            </h4>
            <p className='text-justify'>

             {/* ولدت فكرة شركة الأحساء للسياحة والترفيه ( أحسانا ) من رحم اللجنة السياحية بغرفة الأحساء بعقد التسعينات 
             وكان الهدف الرئيسي منها هو إعداد أرضية وبيئة جاذبة للإستثمارات بالقطاع السياحي والترفيهي وتقديم دراسات مسبقة الإعداد
             لعدة مشاريع سياحية وترفيهيه لتشجيع الشركات والمؤسسات العاملة والمهتمه بالسياحة والترفيه من داخل وخارج الأحساء للتقدم باستثماراتها
             وتنفيذها مستغلة مقومات الأحساء الطبيعية والتاريخية في هذا القطاع. حيث كان تفكير المؤسسين جامحاً قد سبق وقته تجاه الاستثمار في 
             قطاع كان طاردا بالإضافة لوجود قنوات استثمارية أخرى مغرية ولافتقاره لأى تنظيم واضح المعالم .
             فقد سبقت الشركة بإعدادها ( الخريطة الاستثمارية السياحية والترفيهية بواحة الأحساء ولائحتها ) تأسيس الهيئة العامة للسياحة والتراث الوطنى . */}
             
             {t('aboutUsText11')}
            </p>
          </div>  
          <div className="col-12 mb-5" data-aos="fade-up" data-aos-duration="1500">
            <h4 className='my-2 mb-3 logo-blue-text-color'>
               تطبيق قواعد حوكمة الشركات 
            </h4>
            <p className='text-justify'>

              في شركة الأحساء للسياحة والترفيه يتم تنظيم العمل بما يضمن المحافظة علي حقوق المساهمين وذلك من خلال حوكمة الشركة 
              وممارسة العمل المؤسسى الذي يضمن تطور الشركة وتنميتها بحيث يكون لمجلس الإدارة ولجانه الدور الرئيسي في صياغة 
              الرؤية العامة ووضع الأهداف والتخطيط ثم الرقابة على تحقيق هذه الأهداف بينما تركز الإدارة التنفيذية المتخصصة على التشغيل 
              والتنفيذ وتحقيق أهداف الشركة في ضوء موازنات وخطط يعتمدها مجلس الإدارة ويراقب تنفيذها من خلال منظومة سياسات وإجراءات وصلاحيات
              وهيكل تنظيمي ووصف وظيفي يضمن قيام كل إدارة وموظف بدوره التنفيذي في منظومة العمل وبما يحقق محاسبة المسؤولية عن الأداء والنتائج.

            </p>
          </div>
          <div className="col-12 mb-5" data-aos="fade-up" data-aos-duration="1500" >
            <h4 className='my-2 mb-3 logo-blue-text-color'>
               أغراض الشركة 
            </h4>
            <p className='text-justify'>
              تمارس الشركة عدة أنشطة منها إنشاء وتشغيل وصيانة المنشآت والمرافق السياحية والعامة وتنظيم الرحلات السياحية والفعاليات والمهرجانات والمعارض والمؤتمرات 
              والمراكز الترفيهية والمطاعم والفنادق والإستراحات والحدائق النباتية والحيوانية وصالات الألعاب.
            </p>
          </div>
        </div>

        <div className="row">
            <div className="section-header">
              <h2 className="text-center my-5 py-2 border-bottom border-5 logo-blue-text-color">المساهمون بالشركة </h2>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-up" data-aos-duration="1200"> 
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-orange-bg-color">
                <h4>راشد بن سعد بن عبدالرحمن الراشد</h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-up" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-orange-bg-color">
                <h4>شركة الكفاح القابضة </h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-up" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-orange-bg-color">
                <h4>شركة مجموعة الحسين والعفالق</h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-up" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-blue-bg-color">
                <h4>شركة أيان للإستثمار </h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4"data-aos="flip-up" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-blue-bg-color">
                <h4>عبد المحسن بن عبدالعزيز الجبر</h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-down" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-blue-bg-color">
                <h4>عبدالعزيز بن محمد العبد القادر</h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-down" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-green-bg-color">
                <h4>شركة الجبر للإستثمار</h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-down" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-green-bg-color">
                <h4>عبدالرؤوف بن عبدالرازق البشير</h4>
              </div>
            </div>
            
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-up" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-green-bg-color">
                <h4>شركة محمد وباسم أبناء ياسين الغدير</h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-up" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-orange-bg-color">
                <h4>أحمد بن عبدالله المظفر</h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-up" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-orange-bg-color">
                <h4>عبدالله بن سعد بن عبدالرحمن الراشد</h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-down" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-orange-bg-color">
                <h4>شركة مجموعة أبناء سلمان العليو</h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-down" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-blue-bg-color">
                <h4>سليمان بن عبدالرحمن الحماد</h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4"data-aos="flip-down" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-blue-bg-color">
                <h4>أحمد بن راشد سعد الراشد</h4>
              </div>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-4" data-aos="flip-up" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-blue-bg-color">
                <h4>
                  عبدالرحمن بن سليمان الحماد  
                  </h4>
              </div>
            </div>
            <div className="col-2"></div>
            <div className="col-xs-12 col-md-6 col-lg-8" data-aos="flip-up" data-aos-duration="1200">
              <div className="px-3 py-2 m-2 rounded-pill text-white text-center logo-green-bg-color">
                <h4>شركة عبداللطيف محمد العرفج وإخوانه القابضه</h4>
              </div>
            </div>
            
        </div>

        {/* <div className="row gy-3 my-5 content">
          <div className="col-xs-12 col-md-6 col-lg-3" data-aos="fade-down">
            <div className="card h-100">
              <div className="card-body">
                <img src={svg} alt="our views" />
                <h5 className="card-title">رؤيتنا </h5>
                <h6 className="card-subtitle mb-2 text-body-secondary">&nbsp;</h6>
                <p className="card-text">
                أن نكون موقعًا سياحيًا وترفيهيًا وتراثيًا
                فريد من نوعة في المنطقة من خلال مواكبة
                أهداف رؤية المملكة في القطاعين السياحي والترفيهي بهدف تحسين جودة الحياة.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-3" data-aos="fade-up">
          <div className="card h-100">
              <div className="card-body">
                <img src={svg2} alt="our goal" />
                <h5 className="card-title">هدفنا </h5>
                <h6 className="card-subtitle mb-2 text-body-secondary">&nbsp;</h6>
                <p className="card-text">
                مواكبة رؤية المملكة في مبادرة السعودية الخصراء
                 من خلال المشاركة في التشجير و زيادة الغطاء النباتي والمساعدة في مكافحة التصحر.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-3" data-aos="fade-down">
          <div className="card h-100">
              <div className="card-body">
              <img src={svg3} alt="our message" />
                <h5 className="card-title">رسالتنا </h5>
                <h6 className="card-subtitle mb-2 text-body-secondary">&nbsp;</h6>
                <p className="card-text">
                نسعى للتميز والارتقاء بالخدمات التي نقدمها للفرد والعائلة بمشاركة المستثمرين لدينا من أبناء الوطن والجهات ذات العلاقة.
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default AboutUs