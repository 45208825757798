import React from "react";
// import logo from "../assets/imgs/logo/logo2.png";
import { useTranslation } from "react-i18next";

import { NavLink } from "react-router-dom";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function Navbar() {

  const [t, i18n] = useTranslation();
  
  const [show, setShow] = useState(false);
  const handleNavClick = () => {
    setShow(false);
  };
  return (
    // <div>
    <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <LazyLoadImage 
              className="d-inline-block"
              src= "https://ucarecdn.com/63651e46-c82a-46e5-a800-f75ec9a88cae/logo.png"
              effect="black-and-white"
              height={"60px"}
              width={"60px"}   
              alt="Jawatha Logo"             
            />
          <small> {t('logo')}</small>
        
        </NavLink>
        <button
          onClick={() => setShow(!show)}
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        
        <div
          className={`collapse navbar-collapse  ${show ? "show" : ""}`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-5 me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink
                className="nav-link active"
                aria-current="page"
                to="/"
                onClick={handleNavClick}
              >
                {t('home')}
                
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={"/about"}
                className="nav-link"
                onClick={handleNavClick}
              >
                {t('aboutUs')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={"/services"}
                className="nav-link"
                onClick={handleNavClick}
              >
              {t('companyProjects')}
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                to={"/events"}
                className="nav-link"
                onClick={handleNavClick}
              >
                الفعاليات
              </NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink
                className="nav-link"
                to={"/jawathaLocation"}
                onClick={handleNavClick}
              >
                خريطة جواثا  
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={"/contact"}
                onClick={handleNavClick}
              >
                {t('contactUs')}
              </NavLink>
            </li>
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                خدمات الشركة
              </a>
              <ul className="dropdown-menu">
                <li>
                  <NavLink
                    className="dropdown-item"
                    to={"/Investor_registration"}
                    onClick={handleNavClick}
                  >
                    تسجيل المستثمرين
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="dropdown-item"
                    to={"/complaints"}
                    onClick={handleNavClick}
                  >
                    الشكاوى والإعتراضات
                  </NavLink>
              
                </li>
              </ul>
            </li> */}
            {/* <li className="nav-item">
              <NavLink
                className="nav-link"
                to={"/online_tickets"}
                onClick={handleNavClick}
              >
                الحجز الأونلاين
              </NavLink>
            </li>
             */}
             
             
             {/* Change Language BTN */}

             {/* <li className="nav-item ms-5">
              {
                i18n.language === 'en' && <button className="btn btn-primary mx-2" onClick={()=>{
                  i18n.changeLanguage('ar')
                }}>Ar</button>
                }
              {
                i18n.language === 'ar' && <button className="btn btn-primary mx-2" onClick={()=>{
                  i18n.changeLanguage('en')
                }}>EN</button>
              }
             </li> */}
          </ul>
        </div>
      </div>
    </nav>
    // </div>
  );
}

export default Navbar;
