import React from 'react'
import AboutUs from './AboutUs'
// import Services from './Services';
// import ContactUs from './ContactUs';


function LayoutAbout() {
  return (
    <div className='mt-5'>
      <div className='mt-5'>&nbsb;</div>
      <AboutUs />
      {/* <ContactUs /> */}

    </div>
  )
}

export default LayoutAbout